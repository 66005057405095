import { Component, OnInit } from '@angular/core';
import { apiservice } from '../service/apiservice.service';
import { ConstantsService } from '../service/constants.service';

@Component({
  selector: 'app-shows',
  templateUrl: './shows.component.html',
  styleUrls: ['./shows.component.scss']
})
export class ShowsComponent implements OnInit {

  public showsList: any;
  constructor(
    public api: apiservice,
    public constant: ConstantsService,
  ) { }

  ngOnInit(): void {
    this.getAllShowsImages();
  }

  getAllShowsImages() {
    this.api.getRequest(this.constant.SHOWS, '').then((res: any) => {
      this.showsList = res['data'];
    }).catch(err => {
      console.log(err);
    });
  }

}
