import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var mybutton = <HTMLButtonElement>document.getElementById("myBtn");
    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () { scrollFunction() };
    function scrollFunction() {
      if (document.body.scrollTop > 60 || document.documentElement.scrollTop > 60) {
        //console.log(document.body.scrollTop)
        mybutton.style.display = "flex";
      } else {
        mybutton.style.display = "none";
      }
    }
  }

  topFunction() {
    window.scroll({ top: 0, behavior: "smooth" })
  }

}
