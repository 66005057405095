<section class="menu menu3 cid-suyjHfRNSe" once="menu" id="menu3">
    <div class="div">
        <button (click)="topFunction()" class="align-items-center justify-content-center" id="myBtn"
            title="Go to top"><i class="mdi mdi-36px mdi-arrow-up-bold"></i></button>
    </div>
    <nav class="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
        <div class="container">
            <div class="navbar-brand">
                <span class="navbar-caption-wrap"><a class="navbar-caption text-black display-7" routerLink="/">
                        <img src="/assets/logo/DB4C0630-345B-4385-81B2-D8A47F94A0A2.PNG" class="logo" alt=""
                            style="width: 100px; height: 100px; ">
                    </a>
                </span>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <div class="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true">
                    <li class="nav-item"><a class="nav-link link text-black display-4" routerLink="/"
                            routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
                            Home</a></li>
                    <li class="nav-item"><a class="nav-link link text-black display-4" routerLink="/aboutus"
                            routerLinkActive="active-link">
                            About Us</a></li>

                    <li class="nav-item"><a class="nav-link link text-black display-4" routerLink="/artists"
                            routerLinkActive="active-link">
                            Artists</a></li>

                    <li class="nav-item"><a class="nav-link link text-black display-4" routerLink="/events"
                            routerLinkActive="active-link">
                            Events</a></li>
                    <li class="nav-item"><a class="nav-link link text-black display-4" routerLink="/contactus"
                            routerLinkActive="active-link">
                            Contact Us</a>
                    </li>
                </ul>
                <div class="icons-menu">
                    <a class="iconfont-wrapper" href="https://www.facebook.com/theartbyrs" target="_blank">
                        <span class="p-2 mbr-iconfont socicon-facebook socicon aa"></span>
                    </a>
                    <!-- <a class="iconfont-wrapper" href="#" target="_blank">
                        <span class="p-2 mbr-iconfont socicon-twitter socicon"></span>
                    </a> -->
                    <a class="iconfont-wrapper" href="https://www.instagram.com/theartbyrs/" target="_blank">
                        <span class="p-2 mbr-iconfont socicon-instagram socicon aa"></span>
                    </a>

                </div>

            </div>
        </div>
    </nav>
</section>