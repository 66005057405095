import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, Router } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { AllBlogsComponent } from './all-blogs/all-blogs.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { ServerTransferStateModule } from '@angular/platform-server';

const routes: Routes = [
  {
    path: 'all-blogs', component: AllBlogsComponent
  },
  {
    path: 'blog-details', component: BlogDetailsComponent
  }
]

@NgModule({
  declarations: [
    AllBlogsComponent,
    BlogDetailsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    HttpClientModule,
    LazyLoadImageModule,
    BrowserTransferStateModule,
  ],
  exports: [
    RouterModule,
  ], providers: [{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }], 
})
export class BlogsModule { }
