<!-- <ngx-spinner bdColor = "rgba(0,0,0,0.8)" size = "large" color = "#a31600" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->
<section class="team1 cid-suylmxCp71 pb-4" *ngIf="artist" id="team1-j">

    <div class="container">
        <div class="row">
            <div class="col-12">
                <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2">
                    <strong></strong>
                </h3>

            </div>
            <div class="card">
                <div class="card-wrapper">
                    <div class="row gy-4">
                        <div class="col-12 col-md-4">
                            <div class="image-wrapper">
                                <img [lazyLoad]="api.url + artist.profilePhoto" alt="Mobirise">
                            </div>
                        </div>
                        <div class="col-12 col-md">
                            <div class="card-box">
                                <h5 class="card-title mbr-fonts-style m-0 display-5">
                                    <strong>{{artist.displayName}}</strong>
                                </h5>
                                <h6 class="mbr-fonts-style mb-3 display-4">
                                    <!-- <strong>Programmer</strong> -->
                                </h6>
                                <p class="mbr-text mbr-fonts-style display-7">{{artist.artistBio}}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-12 col-md">
                <p class="mbr-text mbr-fonts-style display-7">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                    and
                    scrambled it to make a type specimen book. It has survived not only five centuries, but also the
                    leap
                    into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                    the
                    release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                    publishing
                    software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <p class="mbr-text mbr-fonts-style display-7">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                    and
                    scrambled it to make a type specimen book. It has survived not only five centuries, but also the
                    leap
                    into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                    the
                    release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                    publishing
                    software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>

                <p class="mbr-text mbr-fonts-style display-7">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                    and
                    scrambled it to make a type specimen book. It has survived not only five centuries, but also the
                    leap
                    into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                    the
                    release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                    publishing
                    software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>

            </div> -->
        </div>

    </div>
</section>
<section class="team1 cid-suylmxCp71 py-4" *ngIf="artist" id="team1-j">
<div class="container" *ngIf="artist.artworks.length==0">
    <div class="row">
        <div class="col-12">
            <h5 class="card-title mbr-fonts-style m-0 display-5">
                <strong>Artwork to be published</strong>
            </h5>
        </div>
    </div>
</div>
    <div class="gal container" *ngIf="artist.artworks.length>0">
       
        <div class="hovereffect item" routerLink="/artwork/{{ag.id}}" *ngFor="let ag of artist.artworks">
            <img [lazyLoad]="api.url + ag.artworkPhoto" style="width:100%">
            <div class="centered1" *ngIf="ag.sold == true">
                <img src="assets/logo/Sold-Icon.png">
            </div>
            <div class="overlay">
                 
                <h5><b>{{ag.artworkTitle}}</b></h5>
                <h5>{{artist.displayName}}</h5>
                <h6 class="pb-3"><small>{{ag.artworkSize}}</small></h6>
            </div>

           
        </div>
        
    </div>
    <div class="icons-menu container mt-4" style="font-size: 20px;">
        <a class="iconfont-wrapper aa" href="tel:{{artist.mobileNumber}}" target="_blank">
            <div class="row my-3">
                <div class="col-auto ">
                    <i class="mdi mdi-cellphone-android" style="color: black;"></i>
                </div>
                <div class="col px-0">
                    <i class="mb-0" style="color: black; text-decoration: none;">+91 {{artist.mobileNumber}}</i>
                </div>
            </div>
        </a>
        <a class="iconfont-wrapper aa" href="mailto:{{artist.emailAddress}}" target="_blank">
            <div class="row my-3">
                <div class="col-auto ">
                    <i class="mdi mdi-email" style="color: black;"></i>
                </div>
                <div class="col px-0">
                    <i class="mb-0" style="color: black; text-decoration: none;">{{artist.emailAddress}}</i>
                </div>
            </div>
        </a>
        <a class="iconfont-wrapper aa" *ngIf="artist.facebookURL!='NULL'" href="{{artist.facebookURL}}" target="_blank">
            <div class="row my-3">
                <div class="col-auto ">
                    <i class="mbr-iconfont socicon-facebook socicon" style="color: black;"></i>
                </div>
                <div class="col px-0">
                    <i class="mb-0" style="color: black; text-decoration: none;">{{artist.displayName}}</i>
                </div>
            </div>
        </a>
        <!-- <a class="iconfont-wrapper" href="#" target="_blank">
        <i><span class="p-2 mbr-iconfont socicon-twitter socicon"></span>Passion within</i>
    </a><br> -->
        <a class="iconfont-wrapper aa" *ngIf="artist.instagramURL!='NULL'" href="{{artist.instagramURL}}" target="_blank">
            <div class="row my-3">
                <div class="col-auto ">
                    <i class="mbr-iconfont socicon-instagram socicon" style="color: black;"></i>
                </div>
                <div class="col px-0">
                    <i class="mb-0" style="color: black; text-decoration: none;">{{artist.displayName}}</i>
                </div>
            </div>
        </a>

    </div>
</section>