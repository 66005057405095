import { Component, OnInit } from '@angular/core';
import { apiservice } from '../service/apiservice.service';
import { ConstantsService } from '../service/constants.service';

@Component({
  selector: 'app-workshops',
  templateUrl: './workshops.component.html',
  styleUrls: ['./workshops.component.scss']
})
export class WorkshopsComponent implements OnInit {

  public workShopList: any;
  
  constructor(
    public api: apiservice,
    public constant: ConstantsService,
  ) { }

  ngOnInit(): void {
    this.getAllWorkshopImages();
  }

  getAllWorkshopImages() {
    this.api.getRequest(this.constant.WORKSHOPS, '').then((res: any) => {
      this.workShopList = res['data'];
    }).catch(err => {
      console.log(err);
    });
  }

}
