<!-- <ngx-spinner bdColor = "rgba(0,0,0,0.8)" size = "large" color = "#a31600" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->
<section class="team1 cid-suylmxCp71" id="team1-j">
  <div class="container" style="margin-top: 20px;">
    <div class="row p-0">
      <div class="col-12">
        <div class="row">
          <div class="col-12 p-0">
            <img src="assets/banner/banner1.PNG" class="img-fluid w-100 plr" alt="..." style="padding-bottom: 60px;">
            <!-- <br/><br/><br/> -->
            <!--<img src="assets/banner/banner3.png" class="img-fluid w-100" alt="...">-->
            <!-- <h2 class="mbr-section-title mbr-fonts-style align-center display-2" style="margin-bottom: 50px;">
              <strong>Artbyrs</strong>
            </h2> -->

            <h3 class="align-center display-4 fontcolor_red" data-heading="Artbyrs">Artbyrs</h3>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 container" style="width: 5000px; padding-top: 100px;">

    <h3 class="align-center display-4 fontcolor_red" style="margin-top: 1.5rem ;" data-heading=" Gallery">
      Gallery 
    </h3><br /><br /><br />
    <div class="d-flex mx-3">
      <!-- <pagination-controls class="mx-auto" (pageChange)="paginationChange($event)"></pagination-controls> -->
    </div>

    <div class="gal container" *ngIf="gallary">

      <div class="hovereffect item" routerLink="/artwork/{{g.id}}" *ngFor="let g of gallary; let i = index">
        <!--paginate: { itemsPerPage: 30, currentPage: p, totalItems: total } -->
        <img [lazyLoad]="api.url + g.artworkPhoto" onerror="this.src = 'assets/images/loader.gif'" style="width:100%" class="test" *ngIf="i + 1 === gallary.length" #theLastList>
        <div class="centered1" *ngIf="g.sold == true">
          <img src="assets/logo/Sold-Icon.png">
        </div>
        <div class="overlay">
          <h5><b>{{g.artworkTitle}}</b></h5>
          <h5 *ngIf="g.artist">{{g.artist.displayName}}</h5>
          <h6 class="pb-3"><small>{{g.artworkSize}}

            </small></h6>
          <!-- <a class="info" routerLink="/artwork">
          <h5>Click here</h5>
        </a> -->
        </div>
        <img [lazyLoad]="api.url + g.artworkPhoto" onerror="this.src = 'assets/images/loader.gif'" style="width:100%" *ngIf="i + 1 != gallary.length">
        <div class="centered1" *ngIf="g.sold == true">
          <img src="assets/logo/Sold-Icon.png">
        </div>
        <div class="overlay">
          <h5><b>{{g.artworkTitle}}</b></h5>
          <h5 *ngIf="g.artist">{{g.artist.displayName}}</h5>
          <h6 class="pb-3"><small>{{g.artworkSize}}

            </small></h6>
          <!-- <a class="info" routerLink="/artwork">
          <h5>Click here</h5>
        </a> -->
        </div>
        <!-- <div class="overlay">
          <div class="content-wrap">
            <h6 class="mbr-role mbr-fonts-style align-center mb-5 display-4">
                <button type="button " class="btn" style="color: white; background-color: red; opacity: 0.5;" >Sold Out</button>
                <img src="assets/logo/images.jpg">
            </h6>
        </div>
        </div> -->
      </div>
    </div>
    <div class="d-flex my-5 mx-3">
      <!-- <pagination-controls class="mx-auto" (pageChange)="paginationChange($event)"></pagination-controls> -->
    </div>
  </div>
</section>