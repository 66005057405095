import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { apiservice } from 'src/app/service/apiservice.service';
import { ConstantsService } from 'src/app/service/constants.service';
// import { storageService } from 'src/app/service/storage.service';
import * as moment from 'moment';
import { CookiesService } from 'src/app/service/cookies.service';
 @Component({
  selector: 'app-all-blogs',
  templateUrl: './all-blogs.component.html',
  styleUrls: ['./all-blogs.component.scss']
})
export class AllBlogsComponent implements OnInit {
  blogdata: any;

  constructor( public api: apiservice,
    public constant: ConstantsService,
    public spinner: NgxSpinnerService,
    // public storage: storageService,
    public cookie: CookiesService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.getblog()
  }


  getblog(){
    this.api.getRequest(this.constant.Blog,'').then((res:any)=>{
      this.blogdata = res['data'];
     this.blogdata.forEach((element:any) => {
       element.publishDate = moment(element.publishDate).format('YYYY-MM-DD');
     });


    })
  }


  blogpage(data:any){
    this.cookie.update('blogdata',JSON.stringify(data) )
    this.router.navigate(['/blog-details'])
  }

}
