import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
// import { storageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class apiservice {

  public tab: any = 'tabhome';
  public userdetails: any;
  public token: any;
  public userRoleidget: any;

  constructor(
    public http: HttpClient,
    public router: Router,
    // public storageService: storageService,
  ) { }

  showHead: boolean = false;
  domain = 'https://api.artbyrs.com/website/';
  url = 'https://api.artbyrs.com/';

  // get request start
  getRequest(api: any, data: any) {
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.domain + api + data).subscribe(res => {
        //if condition data validation
        resolve(res);
      }, error => {
        reject(error);
      })
      // });
    }).catch(err => {
      //  console.log(err);
    });
  }
  // get request end

  // post request start
  postRequest(api: any, data: any) {
    return new Promise((resolve, reject) => {
      //console.log(dbres);
      this.http.post<any>(this.domain + api, data).subscribe(res => {
        //if condition data validation
        resolve(res);
      }, error => {
        reject(error);
      })
    });
  }
  // post request end 

  // put request start
  putRequest(api: any, data: any) {
    return new Promise((resolve, reject) => {
      this.http.put<any>(this.domain + api, data).subscribe(res => {
        //if condition data validation
        resolve(res);
      }, error => {
        reject(error);
      })
    });
  }
  // put request end 

  // patch request start
  patchRequest(api: any, data: any) {
    return new Promise((resolve, reject) => {
      this.http.patch<any>(this.domain + api, data).subscribe(res => {
        //if condition data validation
        resolve(res);
      }, error => {
        reject(error);
      })

    });
  }
  // patch request end 

  // delete request start
  deleteRequest(api: any, data: any) {
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.domain + api + data).subscribe(res => {
        //if condition data validation
        resolve(res);
      }, error => {
        reject(error);
      })
    });
  }
  // delete request end
}





