import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { apiservice } from '../service/apiservice.service';
import { ConstantsService } from '../service/constants.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  
  contact: any;
  addFrom: any;

  constructor(public api: apiservice, public constant: ConstantsService, public FormBuilder: FormBuilder, public toastr: ToastrService,) {
    this.addFrom = this.FormBuilder.group({
      Name: new FormControl('', Validators.compose([Validators.minLength(2), Validators.maxLength(25), Validators.required, Validators.pattern("^[A-Za-z]{2,}([ ][A-Za-z]{1,})?$")])),
      Email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$")]),
      Message: new FormControl('', [Validators.required]),
    })
   }

  ngOnInit(): void {
  }

  addcontact() {
    var data = {
      'name': this.addFrom.value.Name,
      'email': this.addFrom.value.Email,
      'message': this.addFrom.value.Message,
    }
    this.api.postRequest(this.constant.CONTACT, data).then((res:any) => {
      this.toastr.success(res['message']);
    }).catch(err => {
      console.log(err);
    })
  }

}
