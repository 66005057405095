import { Component, OnInit } from '@angular/core';
import { apiservice } from '../service/apiservice.service';
import { ConstantsService } from '../service/constants.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  public about: any;

  constructor(
    public api: apiservice,
    public constant: ConstantsService) { }

  ngOnInit(): void {
    this.getabout();
  }

  getabout() {
    this.api.getRequest(this.constant.CONTENT, '').then((res: any) => {
      if (res) { this.about = res['data']; }
    }).catch(err => {
      console.log(err);
    });
  }

}
