<!-- <ngx-spinner bdColor = "rgba(0,0,0,0.8)" size = "large" color = "#a31600" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->
<section class="team1 cid-suylmxCp71" id="team1-j" style="margin-top: 30px; margin-bottom: 20px;">



    <div class="container pt">
        <div class="row gy-4 align-items-stretch justify-content-center">
            <div class="col-12">
                <!-- <h2 class="mbr-section-title mbr-fonts-style align-center mb-4 mt-0 display-2">
                    <strong>Meet the Artists</strong>
                </h2> -->

                <h3 class="align-center display-4 fontcolor_red" data-heading="Meet the Artists">Meet the Artists</h3>
            </div>
            <div class="col-sm-6 col-lg-3 pt" *ngFor="let a of topartist">
                <div class="card-wrap h-100 cursor" routerLink="/artists/{{a.id}}">
                    <div class="image-wrap">
                        <img [lazyLoad]="api.url + a.profilePhoto">
                    </div>
                    <div class="content-wrap px-1">
                        <h5 class="mbr-section-title card-title mbr-fonts-style align-center m-0 display-5">
                            <strong>{{a.displayName}}</strong>
                        </h5>
                        <!-- <h6 class="mbr-role mbr-fonts-style align-center mb-3 display-4">
                            <strong>Programmer</strong>
                        </h6> -->
                        <p class="card-text mbr-fonts-style align-center display-7">
                            <a class="aa" style="font-size: 18px;">Learn More</a>
                        </p>

                        <!-- <div class="social-row display-7">
                            <div class="soc-item">
                                <a href="https://www.facebook.com/Mobirise/" target="_blank">
                                    <span class="mbr-iconfont socicon socicon-facebook"></span>
                                </a>
                            </div>
                            <div class="soc-item">
                                <a href="https://twitter.com/mobirise" target="_blank">
                                    <span class="mbr-iconfont socicon socicon-twitter"></span>
                                </a>
                            </div>
                            <div class="soc-item">
                                <a href="https://instagram.com/mobirise" target="_blank">
                                    <span class="mbr-iconfont socicon socicon-instagram"></span>
                                </a>
                            </div>
                            
                            
                        </div> -->

                    </div>
                </div>
            </div>
            <div class="w-100"></div>
            <div class="col-sm-6 col-lg-3" *ngFor="let a of artist">
                <div class="card-wrap h-100 cursor" routerLink="/artists/{{a.id}}">
                    <div class="image-wrap">
                        <img [lazyLoad]="api.url + a.profilePhoto">
                    </div>
                    <div class="content-wrap px-1">
                        <h5 class="mbr-section-title card-title mbr-fonts-style align-center m-0 display-5">
                            <strong>{{a.displayName}}</strong>
                        </h5>
                        <!-- <h6 class="mbr-role mbr-fonts-style align-center mb-3 display-4">
                            <strong>Programmer</strong>
                        </h6> -->
                        <p class="card-text mbr-fonts-style align-center display-7">
                            <a class="aa" style="font-size: 18px;">Learn More</a>
                        </p>

                        <!-- <div class="social-row display-7">
                            <div class="soc-item">
                                <a href="https://www.facebook.com/Mobirise/" target="_blank">
                                    <span class="mbr-iconfont socicon socicon-facebook"></span>
                                </a>
                            </div>
                            <div class="soc-item">
                                <a href="https://twitter.com/mobirise" target="_blank">
                                    <span class="mbr-iconfont socicon socicon-twitter"></span>
                                </a>
                            </div>
                            <div class="soc-item">
                                <a href="https://instagram.com/mobirise" target="_blank">
                                    <span class="mbr-iconfont socicon socicon-instagram"></span>
                                </a>
                            </div>
                            
                            
                        </div> -->

                    </div>
                </div>
            </div>
        </div>

    </div>
</section>