import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor(private cookieService: CookieService) { }

  update(key: any, data: any) {
    return new Promise((resolve, reject) => {
      this.cookieService.put(key, JSON.stringify(data));
      resolve(true);
    });
  }

  get(key: any) {
    return new Promise((resolve, reject) => {
      let data: any = this.cookieService.get(key);
      resolve(JSON.parse(data));
    });
  }

  delete(key: any) {
    return new Promise((resolve, reject) => {
      this.cookieService.remove(key);
      resolve(true);
    });
  }
}
