import { Component, OnInit } from '@angular/core';
import { CookiesService } from 'src/app/service/cookies.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  blogSingleData: any = '';

  constructor(
    public cookie: CookiesService
  ) { }

  ngOnInit(): void {
    this.getblog()
  }


  getblog() {
    let data: any = [];
    data = this.cookie.get('blogdata');
    this.blogSingleData = JSON.parse(data)
    console.log(this.blogSingleData)
  }

}
