<div class="container">
    <div class="row">
        <div class="col-12 my-1 mt-5">
           
            <h2 class="mbr-section-title mbr-fonts-style align-center mb-6 margin display-2">
                <strong>Blogs</strong>
            </h2>
        </div>
    </div>
    <div class="blog-list" class="row mt-3 mb-5 justify-content-center">
        <div class="blogs col-12 col-md-6 col-lg-4" *ngFor="let blogfor of blogdata" (click)="blogpage(blogfor)">
            <a><img [lazyLoad]="blogfor.blogThumbnail" onerror="this.src = 'assets/images/loader.gif'" class="img-fluid" alt="image"></a>
            <p class="text mt-4"><i class="bi bi-clock"></i> {{blogfor.publishDate}} </p>
            <p class="text mt-2">{{blogfor.blogName}}</p>
            <p class="line" [innerHTML]="blogfor.blogContent"></p>
            <a class="read-more" style="color: #d31d28;
            font-weight: 400;" >Read
                More<i class="bi bi-chevron-double-right"></i>            
            </a>
        </div>

        <!-- <div class="blogs col-12 col-md-6 col-lg-4">
            <a><img src="assets\images\features4.jpg" class="img-fluid" alt="image"></a>
            <p class="text mt-4"><i class="bi bi-clock"></i> 2021-11-16 </p>
            <p class="text mt-2">Rekha Shivdasani</p>
            <p class="line">What started as one woman’s discovery of her Art back in 2001</p>
            <a class="read-more" style="color: #d31d28;
            font-weight: 400;" routerLink="/blog-details">Read
                More <i class="bi bi-chevron-double-right"></i>
            </a>
        </div> -->
    </div>
</div>