import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { apiservice } from '../service/apiservice.service';
import { ConstantsService } from '../service/constants.service';
import { CookiesService } from '../service/cookies.service';
// import { storageService } from '../service/storage.service';

@Component({
  selector: 'app-artwork',
  templateUrl: './artwork.component.html',
  styleUrls: ['./artwork.component.scss']
})
export class ArtworkComponent implements OnInit {

  selectedartwork: any;
  gallary: any;
  artlist: any = [];
  public i = 0;

  constructor(
    public router: Router,
    public api: apiservice,
    public constant: ConstantsService,
    // public storage: storageService,
    public cookie: CookiesService,
    public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.getgallery(parseInt(params.id));
    });
    this.getSelectedArtGallery();
  }

  getSelectedArtGallery() {
    this.cookie.get('artistArtWorkList').then((res: any) => {
      if (res) {
        res.forEach((element: any) => {
          this.artlist.push(element.id);
        });
      }
    });
  }

  getgallery(id: number) {
    this.api.getRequest(this.constant.SINGLEGALLERY + '?id=' + id, '').then((res: any) => {
      this.gallary = res['data'][0];
      //console.log(this.gallary);
    }).catch(err => {
      console.log(err);
    });
  }

  nextItem() {
    this.i = this.i + 1;
    this.i = this.i % this.artlist.length;
    this.router.navigate(['/artwork/', this.artlist[this.i]]);
  }

  prevItem() {
    if (this.i === 0) {
      this.i = this.artlist.length;
    }
    this.i = this.i - 1;
    this.router.navigate(['/artwork/', this.artlist[this.i]]);
  }

}
