<section class="image1 cid-suykZ2CtEm pb-4" id="image1-9">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 pt">
                <h3 class="align-center display-4 fontcolor_red" data-heading="Our Journey">Our Journey</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12" *ngFor="let a of about">
                <div class="row align-items-center my-4" *ngIf="a.contentPosition=='left'">
                    <div class="col-12 col-lg-6">
                        <div class="image-wrapper">
                            <img [lazyLoad]="a.contentPhoto" onerror="this.src = 'assets/images/loader.gif'"
                                alt="Mobirise">
                        </div>
                    </div>
                    <div class="col-12 col-lg">
                        <div class="text-wrapper">
                            <p class="mbr-text mbr-fonts-style display-7" style="font-size: 21px;"
                                [innerHTML]="a.contentDescription">
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center my-4" *ngIf="a.contentPosition=='right'">
                    <div class="col-12 order-2 order-lg-1 col-lg">
                        <div class="text-wrapper">
                            <p class="mbr-text mbr-fonts-style display-7" style="font-size: 21px;"
                                [innerHTML]="a.contentDescription">
                            </p>
                        </div>
                    </div>
                    <div class="col-12 order-1 order-lg-2 col-lg-6">
                        <div class="image-wrapper">
                            <img [lazyLoad]="a.contentPhoto" onerror="this.src = 'assets/images/loader.gif'"
                                alt="Mobirise">
                            <p class="mbr-description mbr-fonts-style mt-2 align-center display-4">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 ">
                <p class="mbr-text mbr-fonts-style display-7 text-center" style="font-size: 20px;">It's hard to turn the
                    page when you know someone won't be in the next chapter, but the story must go on.<br>
                    Remembered with love, our artists Madhu Kastia & Neha Agarwal.</p>
            </div>
        </div>
    </div>
</section>