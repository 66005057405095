<section class="team1 " style="margin-top: 30px; margin-bottom: 20px;" *ngIf="blogSingleData">

    <div class="container">
        <div class="row gy-4 align-items-stretch justify-content-center">
            <div class="col-12">
                <h2 class="mbr-section-title mbr-fonts-style align-center mb-6 margin display-2" style="font-size:40px">
                    <strong> {{blogSingleData.blogName}}</strong>
                </h2>
            </div>
            <div class="col-6">
                <div class="card-wrap h-100 cursor">
                    <div class="image-wrap">
                        <img [lazyLoad]="blogSingleData.blogThumbnail" class="img-fluid" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-10" [innerHTML]="blogSingleData.blogContent">
              
            </div>
        </div>
    </div>
</section>