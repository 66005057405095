import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { CookiesService } from './../service/cookies.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { apiservice } from '../service/apiservice.service';
import { ConstantsService } from '../service/constants.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChildren('theLastList', { read: ElementRef })
  theLastList!: QueryList<ElementRef>
  gallary: any = [];
  public p: any = 1;
  total: any;
  observer: any;

  constructor(
    public api: apiservice,
    public constant: ConstantsService,
    public spinner: NgxSpinnerService,
    private cookies: CookiesService,
    private router: Router) { }

  ngOnInit(): void {
    this.getgallery()
    this.intersectionObserver()
  }

  ngAfterViewInit(): void {
    this.theLastList.changes.subscribe((d) => {
      if (d.last) this.observer.observe(d.last.nativeElement);
    })
  }

  paginationChange(e: any) {
    this.router.navigate(['/home/', e]);
  }

  getgallery() {
    this.spinner.show();
    this.api.getRequest(this.constant.GALLERY + '?page_no=' + this.p, '').then((res: any) => {
      this.spinner.hide()
      res.data.forEach((element: any) => {
        this.gallary.push(element)
      });
      this.total = res['total_records'];
      this.cookies.update('artistArtWorkList', res['data']);
    }).catch(err => {
      console.log(err);
    });
  }

  intersectionObserver() {
    let options = {
      root: null,
      rootMargins: '0px',
      threshhold: 1.0,
    }
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (this.p < this.total) {
          this.p++;
          this.getgallery()
        }
      }
    }, options)
  }


}
